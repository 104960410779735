import { Tncs } from "~/components/brand_l2/tncs";
import { useModal } from "~/components/modal";
import { DividerV2 } from "~/widgets/divider";
import { Spacer } from "~/widgets/spacer";

export default function PlutoGuidelines(props: { tncResId: string }) {
  const { setIsModalOn, updateModalContentGetter, setThemeMode } = useModal()!;

  const guidelines = [
    "The actual price of the product / experience included in this gift may vary from the amount you select. Please check with the brand for exact pricing.",
    <span class="underline-offset-2 ">
      You can use this gift card to pay for any product / services on this
      brand. Please{" "}
      <u
        class="cursor-pointer"
        onClick={() => {
          updateModalContentGetter(() => {
            return <Tncs tncResId={props.tncResId ?? ""} />;
          });
          setThemeMode("dark");
          setIsModalOn(true);
        }}
      >
        check T&Cs
      </u>
    </span>,
  ];

  return (
    <div class="flex flex-col rounded-xl  bg-[#221C06] p-3  tracking-[-0.13px]">
      <p class=" text-[15px] font-semibold tracking-[-0.15px] text-[#E0B827]">
        Please note
      </p>
      <Spacer height={11} />
      <ul class="flex flex-col gap-2">
        {guidelines.map((guideline, index) => (
          <li class="flex flex-row gap-1 text-[13px] font-normal tracking-[-0.13px] text-baseSecondaryLight ">
            <div>&nbsp&nbsp{`•`}&nbsp&nbsp</div>
            <div>{guideline}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
